<template>
    <Section :options="computedOptions.sectionOptions">
        <div class="product product-list" ref="product">
            <div class="grid-inner g-0">
                <div class="product-image" :style="productImageStyle">
                    <router-link v-if="viewDetailsPath" :to="viewDetailsPath">
                        <img :src="computedOptions.picture" :alt="labels.pictureAlt ? $cms.utils.getComputedLabel(labels.pictureAlt) : $cms.utils.getComputedLabel(labels.title)" />
                    </router-link>
                    <img v-else :src="computedOptions.picture" :alt="labels.pictureAlt ? $cms.utils.getComputedLabel(labels.pictureAlt) : $cms.utils.getComputedLabel(labels.title)" />
                </div>
                <div class="product-desc">
                    <div class="product-title">
                        <h3 v-if="viewDetailsPath">
                            <router-link :to="viewDetailsPath">
                                {{ $cms.utils.getComputedLabel(labels.title) }}
                            </router-link>
                        </h3>
                        <h3 v-else>
                            {{ $cms.utils.getComputedLabel(labels.title) }}
                        </h3>
                        <h4 v-if="labels.subtitle && labels.subtitle != ''">{{ $cms.utils.getComputedLabel(labels.subtitle) }}</h4>
                    </div>
                    
                    <LocalizedContent tag="div" v-if="labels.description" :localizedContent="labels.description" class="description"></LocalizedContent>
                    <div v-else></div>
                    
                    <div class="buttons">
                        <router-link v-if="viewDetailsPath" :to="viewDetailsPath" class="view-details button button-3d">
                            <i class="icon-zoom-in"></i>
                            {{ $t("hospitality.apartment.view-details") }}
                        </router-link>
                        <a v-if="computedOptions.attachement" class="download-attachement button button-3d" :href="computedOptions.attachement" download target="_blank">
                            <i class="icon-cloud-download"></i>
                            {{ $cms.utils.getComputedLabel(labels.download) }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Section>
</template>

<style>

/* We set the size of the picture */
.product-list .product-image {
    max-height:250px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.product-list .product-image > a {
    height:100%;
}

/* We set the size of the picture */
.product-list .product-image img {
    width: auto;
    height: 100%;
    max-height:240px;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.product-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.product-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
    .product-list .product-image {
        width: 100%!important;
        margin-bottom: 20px;
    }
    .product-list .grid-inner {
      flex-wrap: wrap;
    } 
}

/* We display the content next to the picture */
.product-list .product-desc {
    padding-top:10px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    position:relative;
}

@media (max-width: 767.98px) {
    .product-list .product-desc {
      padding-left:10px;
      width:100%;
    } 
}

/* We reduce description margin and ensure it takes possible space */
.product-list .product-desc div.description {
      flex-grow: 1;
      margin-bottom: 10px;
}

.product-list .product-desc div.description ul {
    margin-left:30px;
}

/* We hide the product description on mobile device */
@media (max-width: 767.98px) {
    .product-list .product-desc div.description {
        /* display:none; */
    }
}

/* We set flex and correct wrapping to full buttons bar and add to cart section */
.product-list .buttons,
.product-list .buttons .add-to-cart {
    display:flex;
    flex-wrap: wrap;
}

/* We set margins for details button and add to cart sectino */
.product-list .buttons .view-details,
.product-list .buttons .add-to-cart {
    margin:0px 30px 10px 0px;
}

</style>

<script lang="ts">
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';

import Section, {SectionOptions} from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType } from '@fwk-node-modules/vue'
import { languagesTypes } from '@fwk-client/store/types';
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

/** @cmsOptions */
export interface ApartmentListOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
    /** @cmsType CmsPicture */
    picture?:CmsPicture;
    /** @cmsType CmsFile */
    attachement?:CmsFile;
    /** @cmsType CmsText */
    viewDetailsPageName?:CmsText;
    /** @cmsType CmsText */
    viewDetailsCode?:CmsText
    /** @cmsType CmsText */
    viewDetailsRouteParams?:CmsText
    /** @cmsType CmsNumber */
    pictureWidth?:CmsNumber
}

/** @cmsLabels */
export interface ApartmentListLabels {
    /** @cmsType CmsLabel */
    pictureAlt?:CmsLabel
    /** @cmsType CmsLabel */
    title?:CmsLabel
    /** @cmsType CmsLabel */
    subtitle?:CmsLabel
    /** @cmsType CmsContent */
    description?:CmsContent
    /** @cmsType CmsLabel */
    download?:CmsLabel
}

/** @cmsSlots */
export interface ApartmentListSlots {
    
}


export default defineComponent({
    props: {
        options: Object as PropType<ApartmentListOptions>,
        labels: {
          type: Object as PropType<ApartmentListLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<ApartmentListSlots>
    },
    components : {
        Section,
        LocalizedContent
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:ApartmentListOptions = {
            sectionOptions : {
                section:false
            },
            pictureWidth:340,
            viewDetailsPageName: "hospitality-apartment",
            viewDetailsRouteParams:"apartmentCode",
            ...props.options,
            picture : (props.options && props.options.picture) ? cms.utils.getComputedPictureURL(props.options.picture) : require('@public/img/vigneron-online/no-photo.jpeg'),
            attachement : (props.options && props.options.attachement) ? cms.utils.getComputedPictureURL(props.options.attachement) : undefined,
        };

        const computedLabels:ApartmentListLabels = {
            download : {
                fr : "Télécharger"
            },
            ...props.labels
        }

        var viewDetailsPath = undefined;
        if(computedOptions.viewDetailsPageName && computedOptions.viewDetailsCode) {
            // We display the details page
            var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
            var code = computedOptions.viewDetailsCode;
            var path = {
                name : computedOptions.viewDetailsPageName,
                params : {
                    lang : languageCode,
                    [computedOptions.viewDetailsRouteParams!] : code
                }
            }
            viewDetailsPath = path;
        }

        const productImageStyle = {
            width:computedOptions.pictureWidth + 'px'
        }

        return {
            productImageStyle: productImageStyle,
            computedOptions: {
                ...computedOptions
            },
            viewDetailsPath,
            labels:computedLabels
        }

    }
})
</script>